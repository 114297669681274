import { useRef } from "react";

const BombShooter = () => {
  return (
    <div>
      <canvas width={628} height={628} />
    </div>
  );
};

export default BombShooter;
