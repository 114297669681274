import "./App.css";
import BombShooter from "./BombShooter";

const App = () => {
  return (
    <>
      <BombShooter />
    </>
  );
};

export default App;
